const template = {
 app_name: 'Modus Nuggets seyi',
 client_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1YThjZWE4NWI0YzU2NTVlODI5YjkxYSIsIm5hbWUiOiJNb2R1cyBOdWdnZXRzIHNleWkiLCJ0eXBlIjoiRU5USVRZIiwiaWF0IjoxNzA1NTYxNzY5fQ.dzmIzTdbFEi3KCaT1_HC9SLLI4kk_O6DeIco-2LAnSU',
 payment_engine_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTW9kdXMgTnVnZ2V0cyBzZXlpIiwidHlwZSI6IkNMSUVOVCIsImtleSI6IjMxYTNjMmRjZjRhZDE2OTY1MzU4OTg1NWQ3OGNjYSIsImlhdCI6MTcwNTU2MTc2OH0.EH8LSRItNwte5euBKt0fvCamfVlLsx3xqLItmpCxjLg',
 app_url: 'https://modus-nuggets-seyi-prod-yvy56gdafq-ew.a.run.app',
 about_us: { description: 'test a' },
 newsletter: { description: 'test', sender:  { email: 'sheyie2008@gmail.com' } } ,
 socials: { facebook: '', instagram: '', 
 twitter: '', linkedin: '', 
 whatsapp: '' },
 contact_details: { email: 'sheyie2008@gmail.com', phone: '0711111111' },
 contact_us: { recipient: { email: 'sheyie2008@gmail.com' } } 
};

module.exports = template;