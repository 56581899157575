const colors = {
  primary: '#ff1900',
  secondary: '#000000',
  disabled: '#B0B0B0',
  success: '#00AA00',
  error: '#AA0000',
  borders: { light: '#FFFFFF', gray: '#888888', dark: '#000000' },
  shadows: { light: '#FFFFFF', dark: '#000000' },
  fromGradient: '#F1FCFF',
  toGradient: '#EEFCFF',
  stopGradient: '#EBF8FE'
};

module.exports = colors;