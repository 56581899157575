"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderDetailsPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_order_1 = require("../../../api/calls/order/get_order");
var main_1 = require("../../../reducers/slices/main");
var OrderDetailsPage = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var dispatch = (0, react_redux_1.useDispatch)();
    var params = (0, react_router_dom_1.useParams)();
    var orderId = params.orderId;
    var _h = (0, react_1.useState)(null), details = _h[0], setDetails = _h[1];
    var _j = (0, react_1.useState)(null), deliveryFee = _j[0], setDeliveryFee = _j[1];
    var _k = (0, react_1.useState)(false), isLoading = _k[0], setIsLoading = _k[1];
    (0, react_1.useEffect)(function () {
        setIsLoading(true);
        (0, get_order_1.get_order)({
            _id: orderId !== null && orderId !== void 0 ? orderId : "",
        }).then(function (response) {
            setIsLoading(false);
            if (response.success) {
                setDetails(response.content);
            }
            else {
                dispatch((0, main_1.setMainModal)({
                    title: "Could not get order details!",
                    messages: [response.message],
                }));
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        if (details) {
            //Keep track of the delivery fee and remove it from the product list. It looks like a product, but it's not.
            var deliveryFeeIdx = details === null || details === void 0 ? void 0 : details.product_list.findIndex(function (x) { return x.sku === "d1" && x.pid === "d1"; });
            if (deliveryFeeIdx) {
                var deliveryFeeProduct = details === null || details === void 0 ? void 0 : details.product_list[deliveryFeeIdx];
                if (deliveryFeeProduct) {
                    // Mutate the product list to remove the delivery fee
                    details === null || details === void 0 ? void 0 : details.product_list.splice(deliveryFeeIdx, 1);
                    // Update the details to reflect it in the UI.
                    setDetails(__assign({}, details));
                    // Set the delivery fee to reflect it in the UI.
                    setDeliveryFee(deliveryFeeProduct);
                }
            }
        }
    }, [details]);
    var generatePriceDisplay = function (onPromotion, price, onPromotionPrice) {
        if (!onPromotion)
            return react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({ value: price, decimalPlaces: 2 })), textColor: "text-secondary", bold: true });
        return (react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                    value: onPromotionPrice,
                    decimalPlaces: 2,
                }), textColor: "text-primary", bold: true }),
            react_1.default.createElement("div", { className: "line-through" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                        value: price,
                        decimalPlaces: 2,
                    }), size: "xsmall", textColor: "text-secondary" }))));
    };
    return (react_1.default.createElement("div", { className: "pt-4" },
        react_1.default.createElement(doshx_controls_web_1.ScreenContainerControl, { className: "flex flex-col" }, details ? (react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Order Details", size: "3xlarge", textColor: "text-secondary" }),
                react_1.default.createElement("div", { className: "grid grid-cols-2 lg:grid-cols-4 gap-4" },
                    react_1.default.createElement("div", { className: "flex flex-col" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Order #", textColor: "text-secondary", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(details.code), textColor: "text-secondary", size: "medium" })),
                    react_1.default.createElement("div", { className: "flex flex-col" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Store name:", textColor: "text-secondary", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_b = (_a = details === null || details === void 0 ? void 0 : details.branch) === null || _a === void 0 ? void 0 : _a.display_name) !== null && _b !== void 0 ? _b : ""), textColor: "text-secondary", size: "medium" })),
                    react_1.default.createElement("div", { className: "flex flex-col" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Status:", textColor: "text-secondary", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(details.status), textColor: "text-secondary", size: "medium" })),
                    react_1.default.createElement("div", { className: "flex flex-col" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Payment method:", textColor: "text-secondary", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(details.transaction_details.transaction.type, " using ").concat(details.transaction_details.transaction.transaction_3rd_party, " with ").concat(details.payment_type), textColor: "text-secondary", size: "medium" })),
                    react_1.default.createElement("div", { className: "flex flex-col" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Payment status:", textColor: "text-secondary", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(details.transaction_details.transaction.status), textColor: "text-secondary", size: "medium" })),
                    react_1.default.createElement("div", { className: "flex flex-col" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Order date:", textColor: "text-secondary", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(details.order_date), textColor: "text-secondary", size: "medium" })),
                    ((_c = details.delivery) === null || _c === void 0 ? void 0 : _c.scheduled_time) && (react_1.default.createElement("div", { className: "flex flex-col" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Scheduled delivery time:", textColor: "text-secondary", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatDateTime((_d = details.delivery) === null || _d === void 0 ? void 0 : _d.scheduled_time)), textColor: "text-secondary", size: "medium" }))),
                    react_1.default.createElement("div", { className: "flex flex-col" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Date of payment:", textColor: "text-secondary", size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatDateTime(details.transaction_details.transaction.payment_day)), textColor: "text-secondary", size: "medium" })))),
            react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
            deliveryFee && (react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Delivery fee:", textColor: "text-secondary", size: "small" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({ value: (_e = deliveryFee.price) !== null && _e !== void 0 ? _e : "0", decimalPlaces: 2 }), textColor: "text-secondary" //
                        , size: "medium", bold: true })),
                react_1.default.createElement(doshx_controls_web_1.DividerControl, null))),
            details.product_list && ((_f = details.product_list) === null || _f === void 0 ? void 0 : _f.length) > 0 && (react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement("div", { className: "flex flex-col gap-4 justify-center" }, details.product_list.map(function (product, index) {
                    return (react_1.default.createElement("div", { key: index, className: "grid grid-cols-2 gap-4 ".concat(index == 0 ? "pt-0" : "border-t pt-4") },
                        react_1.default.createElement("div", { className: "flex flex-col lg:flex-row" },
                            react_1.default.createElement("div", { className: "flex flex-col flex-1" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product.title, textColor: "text-secondary", bold: true }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "SKU: ".concat(product.sku), textColor: "text-secondary", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Status: ".concat(product.status), textColor: "text-secondary" })),
                            react_1.default.createElement("div", { className: "flex flex-col" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Quantity: ".concat(product.quantity), textColor: "text-secondary" }),
                                product.variations &&
                                    product.variations.map(function (variant, variantIndex) {
                                        return (react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
                                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(variant.type, ":"), textColor: "text-primary", bold: true }),
                                            react_1.default.createElement(doshx_controls_web_1.BoxControl, { key: variantIndex, borderColor: "border-transparent", addPadding: false, backgroundColor: "bg-secondary" },
                                                react_1.default.createElement("div", { className: "px-2" }, variant.variations &&
                                                    variant.variations.map(function (v) {
                                                        return react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(v.title), size: "small", textColor: "text-white" });
                                                    })))));
                                    }),
                                product.deal &&
                                    react_1.default.createElement("div", { className: "flex flex-row items-center gap-4 pt-4" },
                                        react_1.default.createElement(doshx_controls_web_1.BoxControl, { key: 1, borderColor: "border-transparent", addPadding: false, backgroundColor: "bg-primary" },
                                            react_1.default.createElement("div", { className: "p-2" },
                                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "R".concat(product.deal.discount, " Discount Applied"), size: "small", textColor: "text-white" })))))),
                        react_1.default.createElement("div", { className: "flex flex-col" }, generatePriceDisplay(product.on_promotion, product.price, product.promotion_price))));
                })))),
            react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Total:", textColor: "text-secondary", size: "large", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({ value: details === null || details === void 0 ? void 0 : details.total, decimalPlaces: 2 })), textColor: "text-secondary", size: "large", bold: true })),
            react_1.default.createElement(doshx_controls_web_1.DividerControl, { lineColor: "bg-transparent" }),
            react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Amount Paid:", textColor: "text-primary", size: "xlarge", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({ value: (_g = details.transaction_details.transaction.amount) !== null && _g !== void 0 ? _g : "0", decimalPlaces: 2 })), textColor: "text-primary", size: "xlarge", bold: true })))) : isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "No order details found" })))));
};
exports.OrderDetailsPage = OrderDetailsPage;
